aside {
  margin-top: 2.3em;
  background-color: rgba(244, 245, 250, 1);
  width: 7%;
  transition: width 0.3s ease;
  height: 130vh !important;
  position: sticky;
}

aside:hover {
  width: 16% !important;
}

.flex-column {
  margin-top: 2.5em;
  color: black;
}

.ptag {
  font-size: 14px;
}

.sidebar-image {
  text-align: center;
  padding: 10px;
  background-color: #f4f4f4;
}

.sidebar-image img {
  max-width: 80%;
  height: auto;
}

.nav-sidebar .listview {
  width: 90%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  color: black;
}

.nav-sidebar .listview.active {
  background-color: rgba(49, 55, 116, 1);
  cursor: pointer;
  color: white !important;
}

.nav-sidebar .listview:not(.active) a.nav-link p {
  color: black;
}

.nav-sidebar .listview:not(.active) a.nav-link .icons {
  color: black;
}

.nav-sidebar .listview:not(.active):hover a.nav-link p,
.nav-sidebar .listview:not(.active):hover a.nav-link .icons {
  transition: color 0.3s ease;
  color: black;
}

.nav-sidebar .listview.active a.nav-link .icons,
.nav-sidebar .listview a.nav-link p,
.nav-sidebar .listview a.nav-link .icons {
  transition: color 0.3s ease;
  color: white;
}

.nav-link.clicked {
  background-color: rgba(49, 55, 116, 1);
  border-top-right-radius: 0.2em;
  border-top-right-radius: 0.2em;
}