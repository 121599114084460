.DeskdropDown {
    width: 270px;
    color: white;
}

.DeskdropDown .MuiOutlinedInput-root fieldset {
    border-color: rgba(255, 255, 255, 1);
}

.DeskdropDown .MuiOutlinedInput-root:hover fieldset {
    border-color: rgba(255, 255, 255, 1);
}

.DeskdropDown .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: rgba(255, 255, 255, 1);
}

.DeskdropDown .MuiInputLabel-root {
    color: rgba(255, 255, 255, 1);
}

.DeskdropDown .MuiInputLabel-root.Mui-focused {
    color: rgba(255, 255, 255, 1);
}

.DeskdropDown .MuiSelect-icon {
    color: rgba(255, 255, 255, 1);
}

.DeskdropDown .MuiSelect-icon.Mui-focused {
    color: rgba(255, 255, 255, 1);
}

.DeskdropDown .MuiMenuItem-root {
    color: rgba(255, 255, 255, 1);
}

.DeskdropDown .MuiMenuItem-root.Mui-selected {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
}

.deskcard {
    height: 6em;
}

.dropdowndesk {
    padding-left: 1.3em;
}

.DeskSearch {
    background-color: #313774;
    color: white;
}

.desktitle {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    margin: 0.3em 0em 0.8em 0.8em;
}

.cardbodyuserdesk {
    height: 3.7em;
}

.cardheaduserdesk {
    height: 2.6em;
}

.cardbodyuserdesk,
.cardheaduserdesk {
    flex-direction: row;
    width: 99%;
    text-align: center;
    background-color: rgba(255, 255, 255, 1);
    align-items: center;
    transform: scale(1);
    transition: transform 0.2s;
    font-family: 'Roboto', sans-serif;
    margin-left: 0.5em;
    font-size: 14px;
}

.cardheaduserdesk {
    margin-top: 2.5em;
    background-color: rgba(49, 55, 116, 1);
    color: white;
    border: rgba(255, 255, 255, 1);
    box-shadow: none;
}

.headbodydesk {
    color: black;
    border: rgba(255, 255, 255, 1);
    box-shadow: none;
    font-size: 12px
}

.haedtitledesk {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
}

.inputdeskkk {
    margin-top: 2em;
    margin-right: 0.3em;
}

.tabledatadesk {
    margin-top: -1em;
}

.custom-menu-paper {
    max-height: 200px !important;
    max-width: 100px !important;
}

.tabledatadesk {
    position: relative;
}

.expanded-card {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.iconfollowupdesk {
    color: #313774;
}

@media screen and (min-width: 768px) and (max-width: 1180px) {
    .DeskdropDown {
        width: 200px;
        color: white;
    }
}