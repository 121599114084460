.main-footer {
    height: 1em;
    background-color: #cec9c9;
    color: black;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0; 
}

.footerheading {
    text-align: center;
    margin-top: -0.6em;
}