.notfound {
    height: 29em;
    width: 30em;
    margin-left: 25%;
}

.pagenotfound {
    font-size: 'Roboto', sans-serif !important;
    font-size: 2.6em;
    margin: 4em 0em 0em 1.2em;
    font-weight: 500;
}