.viewdekstitle {
    font-size: 20px;
    margin: -1.7em 0em 0.9em 1.7em;
    font-family: 'Roboto', sans-serif;
}

.signdeskkk {
    color: white;
    margin: 0.4em;
}

.viewdeskbody {
    font-size: 16px;
    margin: 0.5em 0em 0.3em 1em;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.addconversational {
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin: 0.3em 0em 0em 0.6em;
}

.addfollowline {
    position: relative;
    width: 220px;
    margin-top: 6px;
    border-bottom: 1.6px solid black;
}

.addfollowline {
    margin-left: 0.6em;
    margin-bottom: 0.6em;
}

.alllabeldesk {
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    margin-bottom: -0.2em;
    font-weight: 200 !important;
}

.addfollowupupsubmit {
    background-color: rgb(49, 55, 116);
    color: white;
    text-align: center;
    display: inline-block;
    margin: auto;
    margin-bottom: 1em;
    margin-top: 1em;
}

.addfollowupupsubmit:hover{
    color: whitesmoke;
}